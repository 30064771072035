<template>
    <div class="jishu_tables">
            <el-table
            :data="tableData"
            style="width: 100%;margin-bottom: 20px;"
            row-key="id"
            border
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">         
                <el-table-column
                label="类别"
                prop="name" width="180">
                </el-table-column>
                <el-table-column
                label="技术描述"
                prop="des">
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120">
                    <template v-if="!scope.row.children && role == 1" slot-scope="scope">
                        <el-button
                        @click.native.prevent="deleteRow(scope.$index, scope.row)"
                        type="text"
                        size="small">
                            <i class="el-icon-delete"></i>
                        </el-button>
                        <el-button
                        @click.native.prevent="updateRow(scope.$index, scope.row)"
                        type="text"
                        size="small">
                            <i class="el-icon-edit"></i>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
</template>
<script>
import {mapState} from "vuex";
export default {
    name:"JianLiJiShu",
    data(){
        return {
            tableData: []
        }
    },
    computed:{
        ...mapState(['role'])
    },
    props:['items'],
    methods:{
        // 删除
        async deleteRow(scode, data){
            this.$datas.meta = data;
            let res = await this.$datas.delete_jishu;
            if(res.status >= 200 && res.status < 300){
                this.$message("删除数据成功");
                for(let i in this.tableData){
                    if(this.tableData[i].name == data.name){
                        this.tableData[i].children.splice(scode-1,1);
                        this.tableData = [...this.tableData];
                        break;
                    }
                }
            }
        },
        //更新 
        updateRow(scode, data){
            this.$router.push({name:"UpdateData", query:{item:data, radio:0}});
        }
    },
    watch:{
        items:{
            handler(newV){
                let kinds = [];
                for(let i in newV){
                    kinds.push(newV[i].jishu.jishu_name);
                }
                kinds = [...new Set(kinds)];
                let datas = [];
                for(let i in kinds){
                    let obj = {name:kinds[i], children:[]};
                    for(let j in newV){
                        if(newV[j].jishu.jishu_name == kinds[i]){
                            obj.children.push({id:newV[j].id, des:newV[j].des, name:kinds[i]});
                            if(!obj.id){
                                obj.id = newV[j].jishu.id+"-"+kinds[i];
                            }
                        }
                    }
                    datas.push(obj);
                }
                this.tableData = datas;
            },
            immediate:true
        }
    }
}
</script>
<style lang="less" scoped>
  .demo-table-expand {
    font-size: 0;
    padding:0 100px;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>