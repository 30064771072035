<template>
    <div class="jishu_tables">
             <el-table
    :data="tableData"
    style="width: 100%">
    <el-table-column type="expand">
                <template slot-scope="props">
                    <el-row :span=24>
                         <el-col :span="21" :offset="2" v-if="role == 1">
                            <div style="text-align:right;">
                                 <el-button size="mini" @click.native.prevent="updateRow(props.row)" circle>
                                    <i class="el-icon-edit"></i>
                                 </el-button>
                                 <el-button size="mini" @click.native.prevent="deleteRow(props)" circle>
                                    <i class="el-icon-delete"></i>
                                 </el-button>
                            </div>
                         </el-col>
                    </el-row>
                    <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item label="公司名称">
                            <span>{{ props.row.gongsi_name }}</span>
                        </el-form-item>
                        <el-form-item label="岗位名称">
                            <span>{{ props.row.gangwei_name }}</span>
                        </el-form-item>
                        
                        <el-form-item style="width:100%;" label="岗位职责">
                            <div v-html="props.row.gangwei_work"></div>
                        </el-form-item>
                    </el-form>
                </template>
                </el-table-column>
                <el-table-column
                label="公司名称"
                prop="gongsi_name">
                </el-table-column>
            </el-table>
        </div>
</template>
<script>
import {mapState} from "vuex";
export default {
    name:"JianLiGS",
    data(){
        return {
            tableData:[]
        }
    },
    props:["items"],
    computed:{
        ...mapState(['role'])
    },
    methods:{
        // 删除
        async deleteRow(props){
            console.log(props);
            this.$datas.meta = props.row;
            let res = await this.$datas.delete_gs;
            if(res.status >= 200 && res.status < 300){
                this.$message("删除数据成功");
                this.tableData.splice(props.$index,1);
            }
        },
        //更新 
        updateRow(data){
            this.$router.push({name:"UpdateData", query:{item:data, radio:1}});
        }
    },
    watch:{
        items(newV){
            let datas = [...newV];
            this.tableData = datas.reverse();
        }
    }
}
</script>
<style lang="less" scoped>
  .demo-table-expand {
    font-size: 0;
    padding:0 100px;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>