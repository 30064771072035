<template>
    <div class="jishu_tables">
             <el-table
    :data="tableData"
    style="width: 100%">
    <el-table-column type="expand">
                <template slot-scope="props">
                    <el-row :span=24 v-if="role == 1">
                         <el-col :span="21" :offset="2">
                            <div style="text-align:right;">
                                 <el-button size="mini" @click.native.prevent="updateRow(props.row)" circle>
                                    <i class="el-icon-edit"></i>
                                 </el-button>
                                 <el-button size="mini" @click.native.prevent="deleteRow(props)" circle>
                                    <i class="el-icon-delete"></i>
                                 </el-button>
                            </div>
                         </el-col>
                    </el-row>
                    <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item label="项目名称">
                            <span>{{ props.row.xm_name }}</span>
                        </el-form-item>
                        <el-form-item label="项目周期">
                            <span>{{ !props.row.xm_time ? "暂无":props.row.xm_time}}</span>
                        </el-form-item>
                        <!-- <el-form-item v-if="props.row.gongsi" label="研发公司">
                            <span>{{ props.row.gongsi.gongsi_name }}</span>
                        </el-form-item> -->
                        <el-row :span=24>
                            <el-form-item style="width:100%;" label="项目简介">
                                <span>{{ props.row.xm_jianjie }}</span>
                            </el-form-item>
                        </el-row>
                        <el-row :span=24>
                            <el-form-item style="width:100%;" label="项目职责">
                                <div v-html="props.row.xm_zhize"></div>
                            </el-form-item>
                        </el-row>
                        <el-row :span=24>
                            <el-form-item style="width:100%;" label="技术栈">
                                <span>{{ props.row.xm_jishu }}</span>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </template>
                </el-table-column>
                <el-table-column
                label="项目名称"
                prop="xm_name">
                </el-table-column>  
                <el-table-column
                label="项目周期"
                prop="xm_time">
                </el-table-column>               
            </el-table>
        </div>
</template>
<script>
import {mapState} from "vuex";
export default {
    name:"JianLiXM",
    data(){
        return {
            tableData: []
        }
    },
    computed:{
        ...mapState(['role'])
    },
    props:['items'],
    methods:{
        // 删除
        async deleteRow(props){
            this.$datas.meta = props.row;
            let res = await this.$datas.delete_xm;
            if(res.status >= 200 && res.status < 300){
                this.$message("删除数据成功");
                this.tableData.splice(props.$index,1);
            }
        },
        //更新 
        updateRow(data){
            this.$router.push({name:"UpdateData", query:{item:data, radio:2}});
        }
    },
    watch:{
        items(newV){
            let datas = [...newV];
            this.tableData = datas.reverse();
        }
    }
}
</script>
<style lang="less" scoped>
  .demo-table-expand {
    font-size: 0;
    padding:0 100px;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>