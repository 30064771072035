<template>
    <div class="jianli">
        <div class="add" v-if="role == 1">
            <i
                :class="['iconfont icon-add']"
                @click="addJianLi()"
                ></i>
        </div>
        <div class="kind">
            <el-radio v-for="item, index in kinds" :key="index" v-model="radio" :label="index">{{item}}</el-radio>
        </div>
        <component :is="jishuName" :items="items"></component>
    </div>
</template>
<script>
import JianLIJiShu from "./JianLi/JianLiJiShu.vue";
import JianLIGS from "./JianLi/JianLiGS.vue";
import JianLIXM from "./JianLi/JianLiJiXM.vue";
import {mapState} from "vuex";
export default {
    name:"JianLi",
    data(){
        return {
           radio:0,
           kinds:['技术点描述', "公司描述", "项目描述"],
           jishuName:"JianLIJiShu",
           items:[]
        }
    },
    computed:{
        ...mapState(['role'])
    },
    methods:{
        addJianLi(){
            this.$router.push({name:"JianLiAdd"});
        }
    },
    watch:{
        radio:{
            async handler(newV){
                this.jishuName = (newV == 0) ? "JianLIJiShu":(newV == 1 ? "JianLIGS":"JianLIXM");
                this.$datas.meta = {radio:newV};
                let res = await this.$datas.get_jianliDatas;
                this.items = res.data;
            },
            immediate:true
        }
    },
    components:{
        JianLIJiShu,
        JianLIGS,
        JianLIXM
    }
}
</script>
<style lang="less" scoped>
    .jianli {
        .add {
            text-align: right;
            padding: 10px 50px;
            i {
                cursor: pointer;
                color: #555;
                font-size: 20px;
                &:hover {
                    color: #409EFF;
                }
            }
        }
        .kind {
            margin: 20px 0;
        }
    }
  .demo-table-expand {
    font-size: 0;
    padding:0 100px;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>